$primary: #333a51;
$info: #165E73;
$success: #006612;
$warning: #FDC143;
$danger: #AF1A12;
$text-muted: #6f7479;

@import '../common.scss';

@import '../accessible';

#main-page {
    a:not(.nav-link):not(.btn):not(.alert-link):not(.re-icon):not(.page-link):not(.badge){
        color: $info;
    }
}

.bg-primary-dark {
    background: mix(black, $primary, 25%);
}